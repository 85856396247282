.stickyAccordion {
  position: fixed;
  margin-left: 3px;
  /* border: 1px solid lightgrey; */
  background-color: white;
  top: 80px;
  /* left: 8px; */
  width: 16%;
  overflow-x: hidden;
  overflow-y: auto; }
