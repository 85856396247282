.stickyInfoPanel {
  position: fixed;
  margin-left: 3px;
  border: 1px solid lightgrey;
  background-color: white;
  top: 80px;
  right: 8px;
  width: 16%;
  overflow-x: hidden;
  overflow-y: auto; }

.infoPanel {
  position: relative;
  margin-left: 3px;
  margin-right: 5px;
  border: 1px solid lightgrey;
  background-color: white;
  height: 100%; }

.infoPanelheader {
  height: 30px;
  padding-top: 10px;
  text-align: center;
  color: #24afdf;
  font-size: 24px; }

.infoPanelcontent {
  margin-top: 20px;
  padding-top: 10px;
  padding-left: 13px; }

.infoPanelInitialNote {
  text-align: center;
  margin-top: 170px;
  padding-right: 5px; }

.make-me-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0 15px;
  margin-left: 3px;
  border: 1px solid lightgrey;
  background-color: white;
  height: 100%; }
