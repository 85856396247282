.customBreadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -0.05rem;
  margin-left: -2.4rem;
  list-style: none; }

.containerStyles {
  margin-left: 1px;
  margin-right: 1px; }

.repeatTop {
  background-repeat: repeat-x;
  background-image: url("/iadc/images/bg-repeating1.png");
  width: 100%;
  height: 10px;
  background-color: white; }

.faceSheetTab {
  height: 50px;
  background-color: #323c43;
  color: white;
  width: 100%;
  margin-right: 1px;
  margin-left: 1px;
  border-radius: 4px; }

.menu {
  margin: 0;
  padding-top: 15px;
  list-style: none;
  display: flex;
  justify-content: right;
  width: 100%; }

.liTextStyles {
  color: white;
  margin-right: 30px; }

.selectedLiTextStyles {
  color: #24afdf;
  margin-right: 30px; }

.selectedLiTextStyles:hover {
  text-decoration: none !important; }

.liTextStyles:hover {
  cursor: pointer;
  color: lightgray;
  text-decoration: none !important; }

.datasetsDiv {
  overflow: auto;
  width: 100%;
  position: absolute;
  top: 150px;
  bottom: 90px;
  margin-left: 0px !important; }

.detailLayoutcontainerStyles {
  padding-bottom: 40px;
  background-color: #e6e7e8; }

.breadcrumbStyles {
  margin-top: 10px;
  padding-left: 20px; }

.datasetDetailPage {
  overflow: auto;
  width: 100%;
  position: absolute;
  top: 301px;
  bottom: 90px;
  margin-left: 0px !important;
  background-color: #e6e7e8; }

.footerDiv {
  height: 90px;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: white; }

.repeatBottom {
  background-repeat: repeat-x;
  background-image: url("/iadc/images/bg-repeating3.png");
  width: 100%;
  height: 10px;
  background-color: white; }

.datasetDescDivBtn {
  border: 2px solid #2fafdf !important;
  background-color: #2fafdf !important;
  color: white !important;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px; }

.linkStyles {
  text-decoration: none !important; }

.linkStyles:focus,
.linkStyles:hover {
  text-decoration: none !important; }

.datasetDescDivBtn:focus,
.datasetDescDivBtn:hover {
  border: 2px solid #2fafdf !important;
  background-color: #2fafdf !important;
  color: lightgray !important;
  text-decoration: none !important; }

.datasetDescDivBtn:active {
  outline: none !important;
  box-shadow: none !important;
  background-color: white !important;
  text-decoration: none !important; }
