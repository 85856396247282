.text-white-50 {
  color: rgba(255, 255, 255, 0.5); }

.bg-purple {
  background-color: #a5895c; }

.bg-detail {
  background-color: #63768c; }

.highlight-color {
  background-color: #e9ecef; }

.border-bottom {
  border-bottom: 1px solid #e5e5e5; }

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05); }

.datasetGridDivSelected {
  background: #f69320;
  width: 150px;
  height: 150px;
  margin: 15px;
  text-align: center;
  border: 1px solid #f69320;
  border-radius: 10px;
  display: inline-block; }

.datasetMain {
  background-color: #e6e7e8; }

.datasetGridDiv {
  width: 420px;
  height: 250px;
  margin: 15px;
  text-align: center;
  border: 1px solid #cadde9;
  border-radius: 7px;
  display: inline-flex;
  background-color: white; }

.datasetGridDivTitle {
  background-color: #2fafdf;
  height: 60px;
  text-align: center;
  margin: 0 auto;
  padding-top: 10px; }

.datasetGridDivDesc {
  font-size: 13px;
  width: 420px;
  height: 140px;
  text-align: center;
  padding: 5px;
  overflow: auto; }

.datasetGridDivBtn {
  background-color: #f78d27 !important;
  border-color: #f78d27 !important;
  float: center; }

.datasetGridDivBtn:focus {
  background-color: #f78d27 !important;
  border-color: #f78d27 !important; }

.datasetGridDivBtn:hover {
  color: #fff !important;
  background-color: orange !important;
  border-color: orange !important; }

.datasetGridDivBtn:focus,
.datasetGridDivBtn:active {
  outline: none !important;
  box-shadow: none !important; }

/*
.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #f69320 !important;
  border-color: #f69320 !important;
}*/
/*
.datasetGridDiv .datasetGridDivSelected:hover {
  //background-color: #0A0;
  // background: rgb(202, 221, 233);
  background: orange;
  border: 1px solid orange;
  cursor: pointer;
}

.datasetGridDiv:hover {
  //background-color: #0A0;
  // background: rgb(202, 221, 233);
  background: #73a8ca;
  border: 1px solid rgb(202, 221, 233);
  cursor: pointer;
}*/
.circle {
  height: 100px;
  width: 100px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px; }
